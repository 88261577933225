<template>
  <v-container>
    <v-row dense class="mx-3">
      <v-col cols="12">
        <v-card flat style="background: transparent">
          <v-card-title
            class="HeadingWhiteH2-28Center mt-12 d-block"
            style="word-break: break-word"
            :style="
              $store.state.settings.dark ? 'color: #fff' : 'color:#202224'
            "
          >
            {{ $t("login.confirmEmailHeader") }}
          </v-card-title>
          <v-card-text
            class="Text-Dark-Text-1---18-Center mt-12"
            :style="
              $store.state.settings.dark ? 'color: #fff' : 'color:#202224'
            "
          >
            {{ $t("login.confirmEmailText") }}
          </v-card-text>
          <v-card-actions class="mt-12">
            <v-btn
              height="48"
              block
              depressed
              color="primary"
              class="gradient Heading-White-H4-18-Center"
              @click="$router.safePush({ name: 'travelType' })"
              >Ok</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
